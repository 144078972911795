<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.profilePitcure"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="openModal()"
          class="mb-75 mr-75"
          size="sm"
          variant="primary"
        >
          Ganti Foto
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="updateProfilePic()"
          class="mb-75 mr-75"
          size="sm"
          variant="primary"
        >
          test
        </b-button> -->
        <!-- modal vertical center -->
        <b-modal
          id="uploadModal"
          centered
          ref="uploadModal"
          title="Upload Foto"
          hide-footer
        >
          <b-card-text>
            <!-- Accept specific image formats by extension -->
            <cropper
              ref="cropper"
              class="cropper"
              :src="image.src"
            />
            <b-row
              class="mt-75"
            >
              <b-col
                sm-6
              >
                <b-button
                  variant="primary"
                  block
                  @click="$refs.file.click()"
                >
                  Pilih Foto
                </b-button>
                <input
                  ref="file"
                  type="file"
                  hidden
                  accept="image/*"
                  @change="uploadImage($event)"
                >
              </b-col>
              <b-col
                sm-6
              >
                <b-button
                  variant="primary"
                  block
                  @click="cropImage"
                >
                  Simpan
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-modal>
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="NIK"
            label-for="account-nik"
          >
            <b-form-input
              v-model="optionsLocal.employeeCode"
              :placeholder="optionsLocal.employeeCode"
              name="employeeCode"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              :placeholder="optionsLocal.email"
              name="email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Phone"
            label-for="account-phone"
          >
            <b-form-input
              v-model="optionsLocal.phone"
              name="phone"
              :placeholder="optionsLocal.phone"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="account-fullname"
          >
            <b-form-input
              v-model="optionsLocal.employeeName"
              :placeholder="optionsLocal.employeeName"
              name="employeeName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nama Panggilan"
            label-for="account-shortname"
          >
            <b-form-input
              v-model="optionsLocal.employeeShortName"
              :placeholder="optionsLocal.employeeShortName"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Divisi"
            label-for="account-division"
          >
            <b-form-input
              v-model="optionsLocal.division"
              :placeholder="optionsLocal.division"
              name="company"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Unit"
            label-for="account-units"
          >
            <b-form-input
              v-model="optionsLocal.units"
              :placeholder="optionsLocal.units"
              name="units"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Posisi"
            label-for="account-jobPositions"
          >
            <b-form-input
              v-model="optionsLocal.jobPositions"
              :placeholder="optionsLocal.jobPositions"
              name="jobPositions"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-badge
            variant="light-secondary"
            class="font-italic"
          >
            Registered on {{ optionsLocal.dateRegistered }}
          </b-badge>
        </b-col>

        <!-- alert -->
        <b-col
          v-if="optionsLocal.emailVerifiedAt === null"
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading font-italic">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-else
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="success"
            class="mb-50"
          >
            <h4 class="alert-heading font-italic">
              Your email is confirmed.
            </h4>
          </b-alert>
        </b-col>

        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            disabled
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm()"
            disabled
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Cropper } from 'vue-advanced-cropper'
import Stencil from '@/views/account/Stencil.vue'
import 'vue-advanced-cropper/dist/style.css'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    Cropper,
    Stencil,
  },
  directives: {
    Ripple,
  },
  // props: ['options'],
  data() {
    return {
      // optionsLocal: this.options,
      optionsLocal: {},
      profileFile: null,
      empCode: null,
      image: {
        src: null,
        type: 'image/*',
      },
    }
  },
  mounted() {
    this.getEmployee()
  },
  methods: {
    getEmployee() {
      // const response = JSON.parse(localStorage.getItem('userData'))
      // const body = { employee_code: response.employee_code }
      axios.post('/employee/detil', JSON.parse(localStorage.getItem('userData'))).then(res => {
        //console.log(res.data.data)
        this.optionsLocal = res.data.data
        this.empCode = JSON.parse(localStorage.getItem('userData'))
        // this.image.src = res.data.data.profilePitcure
        // console.log(this.image.src);
        // console.log(this.options);
      })
    },
    openModal(){
      this.$refs["uploadModal"].show()
    },
    cropImage(blob) {
      const {canvas} = this.$refs.cropper.getResult()
      canvas.toBlob(result  => {
        //console.log(result);
        
        let formData = new FormData();
        formData.append('employee_code', this.empCode.employee_code)
        formData.append('profile_pic', result)
        axios.post('/employee/updateProfilePicure', formData)
          .then(response =>{
            //console.log(response);
            this.getEmployee()
            this.$refs['uploadModal'].hide()
            this.$store.dispatch('auth/get_user')
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() =>{
            
          }
            
          )
      })
      // const newTab = window.open();
      // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
      //   "image/jpeg"
      // )}"></img>`;
      // if(canvas){ 
      //   if (this.image.src) {
      //     URL.revokeObjectURL(this.image.src)
      //   }
      //   // const blob = URL.createObjectURL(files[0])
      //   // console.log(blob);
      // }
      // if (canvas) {
      //   const formData = new FormData();
      //   this.canvas.toBlob(async blob => {
      //     formData.append(
      //       "profile_pic",
      //       blob,
      //       "test.png"
      //     );
      //     formData.append('employee_code', this.employee_code)

      //     axios.post('/employee/updateProfilePicure', formData).then(response =>{
      //       console.log(response);
      //     })
      //   })
      // }
      // const newTab = window.open()
      // newTab.document.body.innerHTML = `<img src="${result.canvas.toDataURL(
      //   this.image.type,
      // )}"></img>`
      //console.log(result)
      // this.$refs.cropper.getCroppedCanvas({
      //     width: 200,
      //     height: 200
      //   }).toBlob(blob => {
      //   const formData = new FormData();
      //   formData.append('employee_code', this.empCode.employee_code)
      //   formData.append('profile_pic', blob)

      //   axios.post('/employee/updateProfilePicure', formData).then(response =>{
      //       console.log(response);
      //     })
      // })
    },
    uploadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target
      // console.log(files);
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])
        // console.log(this.blob);
        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.image = {
          src: blob,
          type: files[0].type,
        }
      }
    },
    // selectImage () {
    //     this.$refs.fileInput.click()
    // },
    // pickFile () {
    //   let input = this.$refs.fileInput
    //   let file = input.files
    //   if (file && file[0]) {
    //     let reader = new FileReader
    //     reader.onload = e => {
    //       this.previewImage = e.target.result
    //     }
    //     reader.readAsDataURL(file[0])
    //     this.$emit('input', file[0])
    //   }
    // },
    // change({ coordinates, canvas }) {
    // 	console.log(coordinates, canvas);
    // },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
